@import '@/sass/variables.scss';
@import '~vuetify/src/styles/styles.sass';









































































































.columns-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 30px;

  @media screen and (min-width: 768px) {
    margin: 35px 0 0;
  }

  @media screen and (min-width: 1400px) {
    justify-content: center;
    grid-template-columns: repeat(auto-fit, minmax(300px, 512px));
    grid-gap: 60px;
    margin: 70px 0 0;
  }

  .col {
    transition: border .3s;
    display: flex;
    flex-direction: column;
    border: 2px solid rgba(255, 255, 255, 0.34);
    border-radius: 26px;
    cursor: pointer;
    position: relative;
    z-index: 1;

    &:after {
      content: '';
      position: absolute;
      top: 13px;
      right: -18px;
      background: rgba(255, 255, 255, 0.08);
      height: 92%;
      width: 100%;
      border-radius: 26px;
      z-index: -1;
    }

    &:hover {
      border: 2px solid #2AF3F3;
    }

    &__top-bar {
      padding: 20px 15px;
      position: relative;
      border-bottom: 2px solid rgba(255, 255, 255, 0.34);

      @media screen and (min-width: 768px) {
        padding: 40px 30px;
      }

      @media screen and (min-width: 1400px) {
        padding: 40px 66px;
      }

      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: rgba(255, 255, 255, 0.08);
        backdrop-filter: blur(49px);
        border-top-left-radius: 26px;
        border-top-right-radius: 26px;
        overflow: hidden;
      }
      
      .btn {
        position: relative;
        z-index: 1;
      }
    }

    &__top-bar-title-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      text-align: left;
      margin: 0 0 15px;
      position: relative;
      z-index: 1;

      @media screen and (min-width: 1400px) {
        margin: 0 0 25px;
      }
    }

    &__title {
      font-size: 21px;
      line-height: 1;
      color: #fff;
      letter-spacing: 0.04em;
      font-weight: 500;
      margin: 0 10px 0 0;
      width: calc(100% - 130px);

      @media screen and (min-width: 768px) {
        font-size: 24px;
      }

      @media screen and (min-width: 1400px) {
        font-size: 38px;
      }

      @media screen and (min-width: 1900px) {
        font-size: 44px;
      }
    }

    &__image {
      width: 50px;
      height: 50px;
      flex-shrink: 0;

      @media screen and (min-width: 1400px) {
        width: 80px;
        height: 80px;
      }

      @media screen and (min-width: 1900px) {
        width: 120px;
        height: 96px;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: scale-down;
      }
    }

    &__body {
      padding: 20px 15px 26px;
      position: relative;
      box-shadow: inset 0 7.26345px 9.07931px rgba(34, 39, 47, 0.14);
      flex-grow: 1;

      @media screen and (min-width: 768px) {
        padding: 40px 30px;
      }

      @media screen and (min-width: 1400px) {
        padding: 40px 66px;
      }

      &:after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(16, 32, 50, 0.44);
        backdrop-filter: blur(73px);
        border-bottom-left-radius: 26px;
        border-bottom-right-radius: 26px;
        overflow: hidden;
      }

      .decor-box {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        min-width: 200px;
        min-height: 200px;
        opacity: 0.26;
        filter: blur(10px);

        @media screen and (min-width: 768px) {
          min-width: 410px;
          min-height: 285px;
        }

        &.green {
          background-color: #29FD2E !important;
        }

        &.blue {
          background-color: #0F7EF7 !important;
        }
      }
    }

    &__body-list {
      text-align: left;
      font-size: 14px;
      position: relative;
      z-index: 1;
      padding: 0;
      margin: 0;

      @media screen and (min-width: 768px) {
        font-size: 16px;
      }

      @media screen and (min-width: 1400px) {
        font-size: 21px;
      }

      li {
        line-height: 1;
        margin: 0 0 10px;
        padding: 0 0 0 29px;
        position: relative;

        @media screen and (min-width: 768px) {
          margin: 0 0 21px;
        }

        &:last-child {
          margin: 0;
        }

        &:before {
          content: '';
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 0;
          background-repeat: no-repeat;
          background-size: cover;
          background-position: 50% 50%;
          background-image: url('~@/assets/icons/icon-list-verification-block.svg');
          width: 19px;
          height: 19px;
        }
      }
    }
  }
}
