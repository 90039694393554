@import '@/sass/variables.scss';
@import '~vuetify/src/styles/styles.sass';










































.verification-wrapper {
  min-height: calc(100vh - 183px);
}
