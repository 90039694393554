@import '@/sass/variables.scss';
@import '~vuetify/src/styles/styles.sass';


















































































@import '@/assets/scss/_variables.scss';
.verification-root {
  height: $page-container-height;
}
