@import '@/sass/variables.scss';
@import '~vuetify/src/styles/styles.sass';































































































































.list-description {
  padding: 40px 0 0;

  @media screen and (min-width: 768px) {
    position: relative;
    overflow: hidden;

    &:after {
      content: '';
      position: absolute;
      top: 52%;
      transform: translateY(-50%);
      right: -17%;
      background-image: url("~@/assets/img/_common/business-verification-image.svg");
      background-position: 50% 50%;
      background-repeat: no-repeat;
      background-size: cover;
      width: 1060px;
      height: 828px;
      opacity: .2;
      z-index: 0;
    }
  }
}

.item-description {
  border-bottom: 1px solid rgba(#2AF3F3, 0.1);
  padding: 0 0 24px 20px;
  margin: 0 0 44px;
  z-index: 1;

  @media screen and (min-width: 768px) {
    padding: 0 0 24px 50px;
  }
}

.item-description-holder {
  display: flex;
  align-items: center;
  max-width: 970px;
}

.image-holder {
  display: none;

  @media screen and (min-width: 768px) {
    display: block;
    width: 80px;
    height: 123px;
    margin: 0 43px 0 0;
    flex-shrink: 0;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: scale-down;
  }
}

.right-bar {
  text-align: left;
  color: #fff;

  .row-title {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: 0 0 20px;
  }

  .item-number {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 26px;
    height: 26px;
    border-radius: 50%;
    border: 1px solid rgba(255, 255, 255, 0.21);
    color: #fff;
    font-size: 20px;
    font-weight: bold;
    margin: 0 10px 0 0;
  }

  h3 {
    font-size: 24px;
    line-height: 1;
    width: calc(100% - 36px);

    @media screen and (min-width: 1200px) {
      font-size: 32px;
    }
  }

  ul {
    padding: 0 0 0 33px;
    margin: 0 0 16px;

    li {
      position: relative;
      margin: 0 0 10px;
      padding: 0 0 0 16px;
      font-size: 16px;
      line-height: 22px;

      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 7px;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: #2AF3F2;
      }
    }
  }

  h4 {
    font-size: 18px;
    line-height: 1;
    margin: 0 0 16px;
  }

  p {
    margin: 0;
    line-height: 17px;
  }
}

.btn-wrapper {
  max-width: 530px;
  margin: 0 auto;
}
